import arrayToTree from 'array-to-tree'
import consola from 'consola';
//import { useProducts } from './products'

export const useAppGlobalData = () => {
    let settings: any = inject('globalSettings');
    const { getProductCategories, getProductCollectionsMegaMenu, getProductBrandsMegaMenu } = useProducts(settings);

    const sortTree = (tree: Array<any>, key: string, childrenProperty: string = "childs") => {
        if (Array.isArray(tree) && tree.length > 0) {
            return tree.sort((a: any, b: any) => a[key] - b[key]).map((item: any) => {
                let childs = item[childrenProperty]
                return {
                    ...item,
                    [childrenProperty]: sortTree(childs, key, childrenProperty)
                }
            })
        } else
            return []
    }

    const getDataMenuHeader = async () => {
        let menu_id = 1

        // switch (languageCode) {
        //     case 'en-US':
        //         menu_id = 2
        //         break
        //     default:
        //         menu_id = 1
        //         break
        // }

        let tree = arrayToTree(settings.menus[menu_id], {
            childrenProperty: 'childs',
            parentProperty: 'parent'
        })
        let data = await Promise.all([getProductCategories(), getProductCollectionsMegaMenu({ query: { limit: 5 } }), getProductBrandsMegaMenu()]).then(data => data).catch(e => { consola.error(e); return [] })
        return {
            menus: sortTree(tree, "sort"),
            categories: data[0],
            collections: data[1],
            brands: data[2]
        }

        // return useAsyncData(() =>
        //     getProductsMegaMenu(languageCode).then((categories) => {
        //         let { data } = categories
        //         let menus = settings.menus[menu_id]

        //         if (menus && Array.isArray(menus) && menus.length > 0) {
        //             menus = menus.map((item) => ({
        //                 ...item,
        //                 childs: item.url === '/en/product' || item.url === '/san-pham' ? data.value : []
        //             }))
        //         }

        //         return menus
        //     })
        // )
    }

    const getDataFooterMenu = () => {
        let menu_id = 2

        // switch (languageCode) {
        //     case 'en-US':
        //         menu_id = 4
        //         break
        //     default:
        //         menu_id = 3
        //         break
        // }

        let tree = arrayToTree(settings.menus[menu_id], {
            childrenProperty: 'childs',
            parentProperty: 'parent'
        })
        return sortTree(tree, "sort")

    }


    return {
        getDataMenuHeader,
        getDataFooterMenu,
    }
}
